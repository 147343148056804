<template>
  <div class="login-bg se-flex se-items-center se-justify-center">
    <img class="login-bg-suspended" src="@/assets/images/bg_login_suspended.png">
    <div class="login-title-border">
      <img class="login-title" src="@/assets/images/web_title.png">
      <div class="login-border">
        <img class="login-border-img" src="@/assets/images/bg_login_border.png">
        <div class="login-border-title">欢迎登录</div>
        <div class="login-form">
          <a-form :form="form" @submit="handleSubmit">
            <a-form-item>
              <a-input
                v-decorator="['username', {
                  normalize: this.$lodash.trim,
                  rules: [
                    { required: true, message: '请输入登录账号' }
                  ]
                }]"
                placeholder="账号"
              >
                <a-icon slot="prefix" type="user" />
              </a-input>
            </a-form-item>
            <a-form-item>
              <a-input
                v-decorator="['password', {
                  rules: [
                    { required: true, message: '请输入登录密码' }
                  ]
                }]"
                placeholder="密码"
                type="password"
              >
                <a-icon slot="prefix" type="lock" />
              </a-input>
            </a-form-item>
            <a-form-item>
              <a-row :gutter="8">
                <a-col :span="18">
                  <a-input
                    v-decorator="['captcha', {
                      normalize: this.$lodash.trim,
                      rules: [{ required: true, message: '请输入验证码' }]
                    }]"
                    placeholder="验证码"
                  >
                    <a-icon slot="prefix" type="safety" />
                  </a-input>
                </a-col>
                <a-col :span="6">
                  <a-tooltip>
                    <template slot="title">
                      点击刷新验证码
                    </template>

                    <a-spin :spinning="loadingCaptchaImg">
                      <img
                        v-if="captchaId.length > 0"
                        :src="captchaImgUrl"
                        class="captcha-img se-cursor-pointer"
                        @click="reloadCaptcha"
                        @load="captchaImgLoaded"
                      >
                    </a-spin>
                  </a-tooltip>
                </a-col>
              </a-row>
            </a-form-item>
            <a-form-item>
              <a-button class="login-submit" html-type="submit" type="primary">
                登录
              </a-button>
            </a-form-item>
          </a-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { newCaptcha, reloadCaptcha } from '@/api/captcha'

export default {
  name: 'Login',
  data() {
    // 节流
    this.calmDown = this.$lodash.throttle(this.calmDown, 4000)
    return {
      form: this.$form.createForm(this, { name: 'login' }),
      captchaId: '',
      captchaImgUrl: '',
      loadingCaptchaImg: false,
      submitting: false,
      busy: false
    }
  },
  watch: {
    $route: {
      handler: function(route) {
        this.redirect = route.query && route.query.redirect
      }
    }
  },
  created() {
    this.newCaptchaId()
  },
  methods: {
    ...mapActions(['Login']),

    // 获取新的验证码id
    newCaptchaId() {
      this.loadingCaptchaImg = true
      newCaptcha().then(res => {
        if (res.code === 0) {
          this.captchaId = res.data.id
          this.fetchCaptchaImgUrl()
        }
      })
    },

    // 根据验证码id刷新验证码
    reloadCaptcha() {
      if (this.loadingCaptchaImg || this.submitting) {
        this.tooBusyMessage()
        return
      }

      if (this.checkBusy()) {
        return
      }

      this.loadingCaptchaImg = true
      reloadCaptcha(this.captchaId).then(res => {
        if (res.code === 0) {
          this.fetchCaptchaImgUrl()
        }
      })
    },

    // 加载验证码图片url
    fetchCaptchaImgUrl() {
      this.captchaImgUrl = `${process.env.VUE_APP_API_BASE_URL}/captcha/${this.captchaId}?v=${Math.random()}`
    },

    tooBusyMessage() {
      this.$message.error('操作太频繁，请稍后再试')
    },

    // 检测是否频繁操作
    checkBusy() {
      this.calmDown()

      if (this.busy) {
        this.tooBusyMessage()
        return true
      } else {
        this.busy = true
        return false
      }
    },

    calmDown() {
      this.busy = false
    },

    // 验证码图片加载完成
    captchaImgLoaded() {
      this.loadingCaptchaImg = false
    },

    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }
      this.form.validateFields((err, values) => {
        if (!err) {
          if (this.loadingCaptchaImg) {
            this.tooBusyMessage()
            return
          }

          if (this.checkBusy()) {
            return
          }

          this.submitting = true
          this.Login(Object.assign({ captcha_id: this.captchaId }, values)).then((res) => {
            if (res.code === 0) {
              if (this.redirect) {
                this.$router.push({ path: this.redirect })
              } else {
                this.$router.push({ name: 'home' })
              }
            }
            this.submitting = false
          }).catch(() => {
            // 重新生成验证码id
            this.newCaptchaId()
            this.form.setFieldsValue({ captcha: '' })
            this.submitting = false
          })
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.login-bg {
  background-image: url('~@/assets/images/bg_login.jpg');
  background-size: cover;
  -webkit-background-size: cover;
  background-position: center 0;
  width: 100vw;
  height: 100vh;

  .login-bg-suspended {
    width: 100%;
    height: 100%;
    position: absolute;
  }

  .login-title-border {
    width: 600px;
    height: 600px;
    position: relative;

    .login-title {
      width: 300px;
      position: absolute;
      top: 50px;
      left: 152px;
    }
  }

  .login-border {
    width: 600px;
    height: 600px;
    position: relative;

    .login-border-img {
      width: 100%;
      padding-top: 150px;
    }

    .login-border-title {
      width: 300px;
      position: absolute;
      top: 180px;
      left: 250px;
      font-size: 20px;
      color: @white;
    }

    .login-form {
      position: absolute;
      top: 230px;
      left: 175px;
      width: 250px;

      .login-submit {
        width: 100%;
      }

      .captcha-img {
        width: 100%;
        height: 100%;
        background-color: @primary-7;
        border-radius: 2px;
      }
    }
  }

  // 输入框
  /deep/ .ant-input {
    background-color: @primary-1;
    color: @white;

    &::placeholder {
      color: @white;
    }
  }

  // 输入框图例
  /deep/ .ant-input-prefix {
    color: @white;
  }
}
</style>
